import "../../styles/login.scss";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { generateTransactionID } from "../../library/utilities";
import axios from "axios";
import { loginURL, registerURL, getStatesURL } from "../../library/URLs";
import { SessionManagement } from "../../library/SessionManagement";
import { ErrorNotification } from "../../components/Notifications";
import ParcelflowLogo from "../../assets/Parcelflow-Logo.svg";
import AccountDetailsIcon from "../../assets/auth/details.svg";
import ServicesIcon from "../../assets/auth/services.svg";
import OperationsIcon from "../../assets/auth/operations.svg";
import {
  PasswordBox,
  PhoneFieldBox,
  SelectBox,
  TextBox,
} from "../../components/InputFields";
import { BsChevronRight } from "react-icons/bs";
import PhoneInput from "react-phone-number-input";
import countries from "../../files/countries.json";
import { UserProfileData } from "../../library/constants";
import Turnstile, { useTurnstile } from "react-turnstile";

const PageHeader = styled.h4`
  font-family: Prompt, sans-serif;
  font-size: 32px;
  line-height: 28.8px;
  margin-bottom: 8px;
  color: #000;

  @media (max-width: 556px) {
    font-size: 2.4rem;
  }
`;

const PageSubheader = styled.h4`
  font-size: 14px;
  color: #667185;
  margin-bottom: 48px;
  font-weight: normal;
`;

const PageFooter = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const Register = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [statesPending, setStatesPending] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    state: "",
    country: "",
  });

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const form = useRef(null);
  const turnstile = useTurnstile();

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  function getDevice() {
    let operatingSystem = "Not known";
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      operatingSystem = "UNIX OS";
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    }

    let currentBrowser = "Not known";
    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      currentBrowser = "Chrome";
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      currentBrowser = "Mozilla Firefox";
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      currentBrowser = "Internet Exployer";
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      currentBrowser = "Edge";
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      currentBrowser = "Safari";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "Opera";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "YaBrowser";
    }

    return currentBrowser + " on " + operatingSystem;
  }

  const getStates = async () => {
    const data = {
      country: formData.country,
    };

    const newData = { ...data, ...UserProfileData(), company_id: "44" };
    setStatesPending(true);
    await axios
      .post(getStatesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setStatesPending(false);

        if (response["data"]["success"] === false) return;

        let states = response?.data?.data?.map((state) => {
          return {
            label: state?.name,
            value: state?.name,
          };
        });
        setStates(states);
      });
  };

  useEffect(() => {
    getStates();
    // eslint-disable-next-line
  }, [formData.country]);

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();

    // const data = new FormData(form.current);

    // const formValues = {};

    // for (let [key, value] of data.entries()) {
    //   formValues[key] = value;
    // }
    // localStorage.setItem("register_company", JSON.stringify(formValues))
    // formValues["device_id"] = "value";
    // formValues["device_name"] = getDevice();
    // formValues["token"] = "value";
    // formValues["transaction_id"] = generateTransactionID(15);

    // setIsSubmitting(true);

    // axios
    //     .post(registerURL, formValues)
    //     .then(function (response) {
    //       console.log(response);
    //       setIsSubmitting(false);

    //       if (response["data"]["success"] === false) {
    //         setErrorMessage(response["data"]["message"]);
    //         setErrorVisibility(true);

    //         setTimeout(() => {
    //           setErrorVisibility(false);
    //         }, 5000);
    //       } else if (response["data"]["success"] === true) {

    //         navigate('/verify-otp', {replace: true});
    //       }
    //     })
    //     .catch(function (error) {
    //       return error;
    //     });
    console.log("register log ", formData);
    navigate("/register/services");
  };

  const country_options = countries?.map((cntry) => {
    return {
      label: cntry?.name,
      value: cntry?.name,
    };
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      phone: code,
    });
  };

  return (
    <div>
      <div className="Auth-Wrap">
        <div className="Auth-Left"></div>
        <div className="Auth-Left-Fixed">
          <div>
            <div className="logo-contain mb-4">
              <img src={ParcelflowLogo} alt="logo" />
            </div>
            <div className="auth-track-contain">
              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon progress-icon-active">
                    <img src={AccountDetailsIcon} alt="icon" />
                  </div>
                  <div className="progress-line progress-line-active"></div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Account details</p>
                  <p className="progress-detail-desc">
                    Provide your details to begin account creation.
                  </p>
                </div>
              </div>

              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon">
                    <img src={ServicesIcon} alt="icon" />
                  </div>
                  <div className="progress-line"></div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Interested service(s)</p>
                  <p className="progress-detail-desc">
                    Which of Parcelflow&apos;s service(s) interests you?
                  </p>
                </div>
              </div>

              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon">
                    <img src={OperationsIcon} alt="icon" />
                  </div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Operations</p>
                  <p className="progress-detail-desc">
                    Further information on services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="logo-contain">
            <p className="text-redirect">
              Already have an account? <Link to="/">Log In</Link>
            </p>
          </div>
        </div>
        <div className="Auth-Right">
          <div className="Auth-form">
            {isError === true ? (
              <ErrorNotification message={errorMessage}>
                This is a notification
              </ErrorNotification>
            ) : null}
            <PageHeader className="page-header">Create Account</PageHeader>
            <PageSubheader>
              Start managing your logistics business with ease
            </PageSubheader>
            <form onSubmit={handleSubmit} ref={form}>
              <Row>
                <Col xs={12} lg={6}>
                  <div className="input-contain mb-4">
                    <label htmlFor="email">First Name</label>
                    <TextBox
                      placeholder="Enter your first name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInput}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-contain mb-4">
                    <label htmlFor="email">Last Name</label>
                    <TextBox
                      placeholder="Enter your last name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInput}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-contain mb-4">
                    <label htmlFor="email">Email</label>
                    <TextBox
                      placeholder="Enter your email address"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInput}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-contain mb-4">
                    <label htmlFor="email">Phone Number</label>
                    <PhoneFieldBox
                      value={formData.phone}
                      onChange={handlePhoneInput}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-contain mb-4">
                    <label htmlFor="email">Password</label>
                    <PasswordBox
                      placeholder="Enter your password"
                      name="password"
                      value={formData.password}
                      onChange={handleInput}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-contain mb-4">
                    <label htmlFor="email">Confirm Password</label>
                    <PasswordBox
                      placeholder="Confirm your Password"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleInput}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-contain mb-4">
                    <label htmlFor="email">Country</label>
                    <SelectBox
                      placeholder="Select your country of operation"
                      name="country"
                      value={formData.country}
                      onChange={handleInput}
                      options={country_options}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="input-contain mb-4">
                    <label htmlFor="email">State</label>
                    <div className="d-flex gap-1">
                      {statesPending && (
                        <span className="spinner-border spinner-grow-sm" />
                      )}
                      <SelectBox
                        placeholder="Select your state of operation"
                        name="state"
                        value={formData.state}
                        onChange={handleInput}
                        options={states}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <Turnstile
                    sitekey="0x4AAAAAAAxQDE30plKns-78"
                    onVerify={(token) => {
                      console.log("captcha log ", token);
                    }}
                  />
                </Col>
              </Row>

              <div className="d-flex flex-row my-5 justify-content-end">
                <button
                  type="submit"
                  className="login-button btn-primary rounded"
                >
                  {isSubmitting === false ? (
                    <span className="button-text d-flex align-items-center justify-content-center">
                      Proceed <BsChevronRight />
                    </span>
                  ) : (
                    <span className="spinner-border spinner-grow-sm" />
                  )}
                </button>
              </div>

              {/* <PageSubheader>
                Already have an account? <a href="/login">Login</a>
              </PageSubheader> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
