import React from "react";
import PriceIcon from "../../../../assets/settings/price_settings/credit_card_gear.svg";
import { Switch } from "@mui/material";
import { styled as mui_styled } from "@mui/material/styles";

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Insurance = () => {
  return (
    <div>
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={PriceIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Insurance Settings
              </p>
              <span>Customize your insurance settings.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Allow Insurance
                </span>
                <span style={{ fontSize: 14 }}>
                  Toggle if insurance is available when booking shipment.
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <ParcelflowSwitch
                  defaultValue="0"
                  name="create_employee_shipment"
                  // onChange={() =>
                  //     updateNotificationSettings(
                  //         "create_employee_shipment",
                  //         handleNotificationStatus(!createShipment),
                  //         1
                  //     )
                  // }
                  // checked={createShipment}
                  className="react-switch"
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Insurance Margin
                </span>
                <span style={{ fontSize: 14 }}>
                  Set profits gained from shipment insurance.
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center btn-primary oval-button">
                <span>Update</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
