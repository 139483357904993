import * as React from "react";
import PaymentMethods from "./Shipments/PaymentMethods";
import Insurance from "./Shipments/Insurance";
import TransporterSettings from "./Shipments/Transporters";

const PriceSettings = () => {
  return <>
    <PaymentMethods />
    <Insurance />
    <TransporterSettings />
  </>;
};

export default PriceSettings;
