import React, { useEffect, useState } from "react";
import PriceIcon from "../../../../assets/settings/price_settings/credit_card_gear.svg";
import { Switch } from "@mui/material";
import { styled as mui_styled } from "@mui/material/styles";
import {
  getRiderSettingsUrl,
  setRiderSettingsUrl,
} from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
import { toast } from "react-toastify";

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const TransporterSettings = () => {
  const [riderSettings, setRiderSettings] = useState({});
  const [statusPending, setStatusPending] = useState(null);

  const fetchRiderSettings = async () => {
    await axios
      .post(getRiderSettingsUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let rider_settings = res?.data?.data;
          setRiderSettings(rider_settings);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchRiderSettings();
    // eslint-disable-next-line
  }, []);

  const updateMethod = async (key, value) => {
    const formData = {
      ...UserProfileData(),
      settings_key: key,
      settings_value: value,
    };

    setStatusPending(key);

    await axios
      .post(setRiderSettingsUrl, formData)
      .then((res) => {
        setStatusPending(null);
        if (res?.data?.success) {
          setRiderSettings({
            ...riderSettings,
            [key]: value,
          });
          toast.success("Payment method updated successfully.");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {});
  };

  const updateRiderSettings = (key, value) => {
    let settings_value = value === "Yes" ? "No" : "Yes";

    updateMethod(key, settings_value);
  };
  return (
    <div>
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={PriceIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Transporter Settings
              </p>
              <span>Customize settings that applies with riders.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Auto Arrange Routes
                </span>
                <span style={{ fontSize: 14 }}>
                  Automatically assign routes to rider
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "route_arrangement" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="route_arrangement"
                    onChange={() =>
                      updateRiderSettings(
                        "route_arrangement",
                        riderSettings?.route_arrangement
                      )
                    }
                    checked={riderSettings?.route_arrangement === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Positional Marking
                </span>
                <span style={{ fontSize: 14 }}>Positional Marking Option</span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "strict_marking" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="strict_marking"
                    onChange={() =>
                      updateRiderSettings(
                        "strict_marking",
                        riderSettings?.strict_marking
                      )
                    }
                    checked={riderSettings?.strict_marking === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Enforced Tracking
                </span>
                <span style={{ fontSize: 14 }}>
                  Make tracking of rider's location compulsory
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "enforced_tracking" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="enforced_tracking"
                    onChange={() =>
                      updateRiderSettings(
                        "enforced_tracking",
                        riderSettings?.enforced_tracking
                      )
                    }
                    checked={riderSettings?.enforced_tracking === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Request OTP
                </span>
                <span style={{ fontSize: 14 }}>
                  Always request OTP before package is delivered.
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "send_delivery_otp" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="send_delivery_otp"
                    onChange={() =>
                      updateRiderSettings(
                        "send_delivery_otp",
                        riderSettings?.send_delivery_otp
                      )
                    }
                    checked={riderSettings?.send_delivery_otp === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Request Signature
                </span>
                <span style={{ fontSize: 14 }}>
                  Always request receiver's signature before package is
                  delivered.
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "request_signature" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="request_signature"
                    onChange={() =>
                      updateRiderSettings(
                        "request_signature",
                        riderSettings?.request_signature
                      )
                    }
                    checked={riderSettings?.request_signature === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransporterSettings;
