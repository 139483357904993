import "../styles/navbar.scss";
import "../styles/sidebar.scss";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  getCompanySettingsURL,
  getDashboardStatisticsURL,
  getUnseenNotificationsURL,
  logoutURL,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { SessionManagement } from "../library/SessionManagement";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsBell, BsLightning } from "react-icons/bs";
import LoadingBar from "react-top-loading-bar";
import { URLConnection } from "../library/URLConnection";
import * as BoxIcons from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { SidebarData } from "./SidebarData";
import SingleMenu from "./SingleMenu";
import { Offcanvas } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import SuperAdminIcon from "../assets/super-admin.png";
import AdminIcon from "../assets/admin.png";
import Search from "../assets/Search.svg";
import NotificationDialog from "../modals/NotificationDialog";
import EnvironmentalDialog from "../modals/EnvironmentalDialog";

const Navbar = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { customerID, shipmentID, shipmentType } = useParams();

  const [mode, setMode] = useState("Live");

  const formatString = (string) => {
    return string
      ?.replace("-", " ")
      ?.split(" ")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  };


  const pageHeaders = [
    {
      url: "/",
      title: "Dashboard",
      sub: "Welcome back,",
    },
    {
      url: "/create-shipment",
      title: "Shipments",
      sub: "Create new shipments",
    },
    {
      url: "/shipments/packages",
      title: "Shipments",
      sub: "All package containers",
    },
    {
      url: "/customers",
      title: "Customers",
      sub: "View all Customers",
    },
    {
      url: `/customer/${customerID}`,
      title: "Customers",
      sub: "View all Customers",
    },
    {
      url: `/shipments/${shipmentType}`,
      title: "Shipments",
      sub: `View ${formatString(shipmentType)}`,
    },
    {
      url: "/calculate-estimates",
      title: "Shipments",
      sub: `Calculate shipment estimate`,
    },
    {
      url: `/customers-wallet`,
      title: "Customers Wallet",
      sub: `View all customers wallet`,
    },
    {
      url: `/shipment/${shipmentID}`,
      title: "Shipments",
      sub: `View shipment details`,
    },
    {
      url: `/transactions`,
      title: "Transactions",
      sub: `View all transactions`,
    },
    {
      url: `/transporters`,
      title: "Transporters",
      sub: `View all riders`,
    },
    {
      url: `/transporter/create-transporter`,
      title: "Transporters",
      sub: `Add new transporter`,
    },
    {
      url: `/stations`,
      title: "Stations",
      sub: `View all stations`,
    },
    {
      url: `/stations/create`,
      title: "Stations",
      sub: `Add new station`,
    },
    {
      url: `/reports`,
      title: "Reports",
      sub: `View all reports`,
    },
    {
      url: `/settings`,
      title: "Settings",
      sub: "View all Account Settings",
    },
    {
      url: `/reports/sales`,
      title: "Sales Report",
      sub: "View Shipment Sales",
    },
    {
      url: `/reports/shipments/options`,
      title: "Shipment Options",
      sub: "View Shipment reports options",
    },
  ];

  const [unseenNotifications, setUnseenNotifications] = useState("0");
  const [showingMenu, setShowingMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showEnvironmentDialog, setShowEnvironmentDialog] = useState(false);

  const handleMenuOpen = () => setOpenMenu(true);
  const handleMenuClose = () => setOpenMenu(false);

  const profileImageURL = SessionManagement.getProfileImageURL();

  const toggleMenu = () => {
    setShowingMenu(!showingMenu);
    setShowNotifications(false);
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    setShowingMenu(false);
  };

  const handleLogoutClick = (e) => {
    ref.current.continuousStart();

    axios
      .post(logoutURL, UserProfileData())
      .then(function (response) {
        SessionManagement.setName("");
        SessionManagement.setEmail("");
        SessionManagement.setCompanyID("");
        SessionManagement.setCompanyName("");
        SessionManagement.setProfileImageURL("");
        SessionManagement.setUserID("");
        SessionManagement.setSessionID("");

        SessionManagement.setAuthenticationStatus("no");

        navigate("/login");
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  useEffect(() => {
    URLConnection(
      getUnseenNotificationsURL,
      UserProfileData(),
      function (response) {
        if (response["data"]["success"] === false) return;

        const unseenNotifications =
          response["data"]["data"]["unseen_notifications"];

        setUnseenNotifications(unseenNotifications);
      }
    );
    const environmentalType = SessionManagement.getEnvironmentalType();
    setMode(environmentalType);
  }, []);

  // document.addEventListener("mousedown", toggleMenu);
  const companyName = SessionManagement.getCompanyName();

  useEffect(() => {
    URLConnection(
      getCompanySettingsURL,
      UserProfileData(),
      function (response) {
        if (response["data"]["success"] === false) return;

        const intracityShipmentProcessor =
          response["data"]["data"]["intracity_shipment_processor"];
        const interstateShipmentProcessor =
          response["data"]["data"]["interstate_shipment_processor"];
        const internationalShipmentProcessor =
          response["data"]["data"]["international_shipment_processor"];
        const isWhatsappSet = response["data"]["data"]["whatsapp"];
        const currentPlan = response["data"]["data"]["current_plan"];

        console.log(currentPlan);

        SessionManagement.setCurrentPlan(currentPlan);
        SessionManagement.setIntracityShipmentProcessor(
          intracityShipmentProcessor
        );
        SessionManagement.setInterstateShipmentProcessor(
          interstateShipmentProcessor
        );
        SessionManagement.setInternationalShipmentProcessor(
          internationalShipmentProcessor
        );
        SessionManagement.setIsWhatsappSet(isWhatsappSet);

        console.log(response);
      }
    );
  }, []);

  const goToNotifications = () => {
    navigate("/notifications");
  };

  let firstname = SessionManagement.getFirstName() ?? "";
  let lastname = SessionManagement.getLastName() ?? "";
  let employee_type = SessionManagement.getEmployeeType() ?? "";
  let email = SessionManagement.getEmail() ?? "";

  const handleEnvironmentalToggle = () => {
    if(mode === "Live") {
      setMode("Test");
      SessionManagement.setEnvironmentalType("Test");
    } else {
      setMode("Live");
      SessionManagement.setEnvironmentalType("Live");
    }
  };

  return (
    <div className="navbar">
      <EnvironmentalDialog 
        isModalVisible={showEnvironmentDialog}
        handleCloseDialog={()=>setShowEnvironmentDialog(false)}
        mode={mode}
        handleChange={handleEnvironmentalToggle}
      />
      <LoadingBar color="#0b613a" ref={ref} />
      <div className="navbar-wrapper">
        {/*<div className="card bg-light border-0 shadow d-flex flex-row flex-grow-1">*/}
        {/*    <div className="card-body d-flex flex-row">*/}
        {/*        <BoxIcons.BiSearch style={{fontSize:20}} />*/}
        {/*        <input className="search-input" placeholder="Search by name or number"/>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div onClick={handleMenuOpen} className="icon-container d-xl-none">
          <FiMenu className="icon" />
        </div>
        <Offcanvas show={openMenu} onHide={handleMenuClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="menu">
              <ul className="menu-list">
                {SidebarData.map((item, index) => {
                  if (
                    companyName !== "520 Logistics" &&
                    item.title === "Partners"
                  ) {
                    return null;
                  }
                  return <SingleMenu item={item} key={index} />;
                })}
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <div className="">
          <p className="section-header">
            {pageHeaders.find((page) => page.url === pathname)?.title ?? ""}
          </p>
          <p className="section-sub">
            {pageHeaders.find((page) => page.url === pathname)?.sub ?? ""}{" "}
            {pathname === "/" && `${firstname} ${lastname}`}
          </p>
        </div>
        <div className="vert-divider search-container">
          <div className="position-relative">
            <input type="text" placeholder="Search here..." />
            <div className="search-icon">
              <img src={Search} alt="search" />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-4">
          <div className="mode-toggle-container">
            <div
              onClick={() => setShowEnvironmentDialog(!showEnvironmentDialog)}
              className={`toggle-button ${
                mode === "Live" ? "mode-active" : "mode-inactive"
              }`}
              style={{whiteSpace:"nowrap"}}
            >
              Real Time
            </div>
            <div
              onClick={() => setShowEnvironmentDialog(!showEnvironmentDialog)}
              className={`toggle-button ${
                mode === "Test" ? "mode-active" : "mode-inactive"
              }`}
              style={{whiteSpace:"nowrap"}}
            >
              Test Mode
            </div>
          </div>

          <div className="navbar-divider"></div>

          <div onClick={toggleNotifications} className="icon-container">
            <BoxIcons.BiBell
              className="icon"
            />
            {parseInt(unseenNotifications) < 1 ? null : (
              <div className="counter">
                {parseInt(unseenNotifications) > 9 ? "9+" : unseenNotifications}
              </div>
            )}
          </div>

          <div className="navbar-divider"></div>

          <div onClick={toggleMenu} className="d-flex gap-2 align-items-center">
            <div className="position-relative">
              <img src={profileImageURL} className="avatar" alt="" />
              <div className=" position-absolute employeetype-icon">
                {employee_type === "Super Admin" ? (
                  <img src={SuperAdminIcon} alt="super admin icon" />
                ) : (
                  <img src={AdminIcon} alt="admin icon" />
                )}
              </div>
            </div>
            <div className="d-flex gap-2 align-items-center">
              {/* <div>
                <div className="employee_detail d-flex gap-1 align-items-center" >
                  <p className="m-0" >{`${firstname} ${lastname}`}</p>
                  <div className="employee-type-pill" >
                    <BsLightning />
                    {employee_type}
                  </div>
                </div>
                <p className="email_detail m-0" >{email}</p>
              </div> */}
              {showingMenu ? (
                <FaChevronUp style={{ cursor: "pointer" }} />
              ) : (
                <FaChevronDown style={{ cursor: "pointer" }} />
              )}
            </div>
          </div>

          <div
            className={`profile-menu ${
              showingMenu ? "profile-menu-visible" : null
            }`}
          >
            <span>Profile</span>
            <span>Support</span>
            <span onClick={handleLogoutClick}>Logout</span>
          </div>
          <div
            className={`profile-menu ${
              showNotifications ? "profile-menu-visible" : null
            }`}
          >
            <NotificationDialog
              isModalVisible={showNotifications}
              handleCloseDialog={() => setShowNotifications(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
